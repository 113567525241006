export const ROUTES = {
  FEED: {
    BROWSE: '/',
    HOT: '/hot',
    TRENDING: '/trending',
    NEW: '/new',
  },
  EXPLORE: {
    BROWSE: '/explore',
  },
  AUTH: {
    SIGN_IN: '/auth/sign-in',
    SIGN_UP: '/auth/sign-up',
    SIGN_OUT: '/auth/sign-out',
    RECOVERY: '/auth/recovery',
  },
  LEGAL: {
    PRIVACY_POLICY: '/legal/privacy-policy',
    TERMS_OF_SERVICE: '/legal/terms-of-service',
  },

  PROFILE: {
    MY: '/profile',
    USER: ({ id }: { id: string }) => `/profile/${id}`,
  },
  LANDING: {
    HERO: '/',
  },

  NOT_FOUND: '/404',
};
